<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <div v-if="itemData" class="data-list-container">
      <div class="header-slicer flex items-center justify-between">
        <div class="header-title-container flex items-center">
            <!-- ADD NEW -->
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
            <span class="header-title">{{itemData.fileName}}</span>
        </div>
        <div>Firm: {{itemData.lawFirmName}}</div>
        <div>Case: {{itemData.caseName}}</div>
      </div>
      <v-overlay :value="isImagePreviewVisible" zIndex="9999999" opacity="0.75">
        <div class="img-fullscreen-container"> 
          <image-zoom
            :regular="imagePreview.s3Url"
            :click-zoom="true"
            img-class="img-fullscreen"
            v-click-outside="onClickOutside"
          ></image-zoom>
        </div>
        <div class="minimize-icon">
          <feather-icon 
            class="cursor-pointer"
            icon="Minimize2Icon" 
            svgClasses="text-black stroke-current" 
            @click.stop="openImagePreview({}, false)"
          />
        </div>
      </v-overlay>
      <div class="center grid mt-5" v-if="itemData.pages && itemData.pages.length > 0">
        <v-carousel v-model="activeSlide" class="mb-10" :dark="true" :hide-delimiters="true">
            <template v-slot:prev="{ on, attrs }">
                <feather-icon 
                    icon="ChevronLeftIcon" 
                    svgClasses="w-36px h-36px" 
                    v-bind="attrs"
                    v-on="on"
                />
            </template>
            <template v-slot:next="{ on, attrs }">
                <feather-icon 
                    icon="ChevronRightIcon" 
                    svgClasses="w-36px h-36px" 
                    v-bind="attrs"
                    v-on="on"
                />
            </template>
            <v-carousel-item 
                v-for="(slide, index) in itemData.pages" :key="index" 
                :src="slide.s3Url"
            >
              <feather-icon 
                class="cursor-pointer image-preview-icon" 
                icon="Maximize2Icon" 
                svgClasses="text-black stroke-current w-8 h-8" 
                @click.stop="openImagePreview(slide, true)"
              />
            </v-carousel-item>
        </v-carousel>
        <div class="horizontal-container">
          <div 
            :class="['item', activeSlide == index ? 'active' : '']" 
            v-for="(item, index) in itemData.pages" :key="index"
            @click.stop="updateSlidePreview(index)"
          >
                <div class="image" :style="{background: `url(${item.s3Url})`}"></div>
                <div class="title">
                    <h4>{{ item.batesNumber }}</h4>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moduleTodo from '@/store/todo/moduleTodo.js'
  import _ from 'lodash'
  import imageZoom from 'vue-image-zoomer';
  import vSelect from 'vue-select'
  import VueHorizontal from 'vue-horizontal';

  export default {
    components: {
      imageZoom,
      vSelect,
      VueHorizontal
    },
    data () {
      return {
        activeSlide: 0,
        isImagePreviewVisible: false,
        imagePreview: {},
        interval: null,
        itemData: {},
        slicingInfo: [],
        accountId: this.$route.params.accountId,
        caseId: this.$route.params.caseId,
        documentId: this.$route.params.documentId,
        isValidBatesRegex: new RegExp(/^([_a-zA-Z\. -]*)?([0-9]+)([_a-zA-Z\. -]*)?$/, "i"),
      }
    },
    created () {
      if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
      }
    },
    mounted () {
      this.refreshData()
    },
    methods: {
      refreshData() {
        this.$vs.loading({
          container: '#data-model-container',
        })
        this.$store.dispatch('todo/fetchSlicerDocument', {
          accountId: this.accountId,
          caseId: this.caseId,
          uuid: this.documentId
        }).then(response => {
          if (response && response.item.pages) {
            response.item.pages.map(page => {
              if (page.batesNumber && this.isValidBatesRegex.test(page.batesNumber)) {
                let batesGroups = page.batesNumber.match(this.isValidBatesRegex);
                if (batesGroups.length > 2 && !isNaN(batesGroups[2])) {
                  page.batesName = batesGroups[1].trim()
                  page.batesNum = parseInt(batesGroups[2])
                }
              }
            })  
          }
          console.log(response);
          this.itemData = response.item;
          if (this.$route.query.bates && this.isValidBatesRegex.test(this.$route.query.bates)) {
            this.findMissingBatesAdjacentPage(this.$route.query.bates)
          }
          this.$vs.loading.close('#data-model-container > .con-vs-loading');
        }).catch(error => {
          console.log(error);
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
      },
      handleNavigation() {
        try {
          if (this.$route.query.source) {
            switch(this.$route.query.source) {
              case "account-case-documents":
              case "case-document-readyforslicing":
              case "case-document-full-bates":
                this.$router.push({name: this.$route.query.source, params: {caseId: this.caseId, accountId: this.accountId}})  
                return true;
            }
          }
        } catch(error) {}
        return false;
      },
      goBack() {
        let result = this.handleNavigation();
        if (!result) {
          this.$router.push({name: "case-document-full-bates", params: {caseId: this.caseId, accountId: this.accountId}})
        }
      },
      updateSlidePreview(index) {
        this.activeSlide = index;
      },
      openImagePreview(slide, isActive) {
        this.imagePreview = slide;
        this.isImagePreviewVisible = isActive
      },
      findMissingBatesAdjacentPage(missingBates) {
        var distance = 999999999;
        var target = 0;
        let batesGroups = missingBates.match(this.isValidBatesRegex);
        if (batesGroups.length > 2 && !isNaN(batesGroups[2])) {
          let batesName = batesGroups[1].trim()
          let batesNumber = parseInt(batesGroups[2])
          if (this.itemData && this.itemData.pages) {
            this.itemData.pages
            .map((page, index) => {
              if (page.batesName && page.batesNum && batesName === page.batesName 
                && Math.abs((batesNumber - page.batesNum)) < distance) {
                distance = Math.abs(batesNumber - page.batesNum);
                target = index;
              }
            });
          }
        }
        this.activeSlide = target;
      },
      onClickOutside() {
        this.openImagePreview({}, false)
      }
    }
  }
</script>
<style lang="scss">
  .vs-card--content {
    background-color: #1E1E1E;
  }
  .vs-checkbox-primary input:checked+.vs-checkbox {
    background-color: transparent;
  }
  .header-slicer {
    position: fixed;
    top: 62px;
    padding: 8px 2.2rem;
    left: 0;
    width: 100%;
    box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, .05);
    z-index: 1000000;
    background-color: #1a1a1a;
  }
  .maximise-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .minimize-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #fff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
  .minimize-icon .feather-icon {
    top: 5px;
    left: 5px;
  }
  .minimize-icon .feather-icon .feather {
    width: 24px;
    height: 24px;
  }
  .vs__search {
    background: none !important;
    color: white !important;
  }
  .vs__dropdown-option--highlight {
    background: #1a1a1a !important;
  }
  .v-window__next {
      position: absolute;
      right: 0;
      height: 36px;
      width: 36px;
      background-color: #1a1a1a;
  }
  .v-window__prev {
      position: absolute;
      left: 0;
      height: 36px;
      width: 36px;
      background-color: #1a1a1a;
  }
  .w-36px {
      width: 36px;
  } 
  .h-36px {
    height: 36px;
  }
  .horizontal-container {
    overflow-x: auto;
    white-space: nowrap;
  }
  .item {
    margin-right: 24px;
    display: inline-block;
    padding-bottom: 24px;
    cursor: pointer;
  }
  .image {
    height: 200px;
    width: 200px;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  .title {
    background-color: #1a1a1a;
  }
  .title h4 {
    text-align: center;
    color: white;
    padding: 4px;
  }
  .v-window__prev, .v-window__next {
    cursor: pointer;
  }

  .horizontal-container .active .title {
    background-color: white;
  }
  
  .horizontal-container .active .title h4 {
    color: #1a1a1a;
    border-top: 1px solid #1a1a1a
  }
  .img-fullscreen-container {
    max-width: 90vw;
    height: 90vh;
  }
  .img-fullscreen-container .vh--outer {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
  }
  .img-fullscreen {
    max-width: 100%;
    max-height: 90vh;
  }
  .image-preview-icon {
    right: 4px;
    top: 4px;
    position: absolute !important;
  }
  .header-title-container {
    flex-grow: 0;
  }
</style>